<template>
  <header-component></header-component>
  <div class="container-full pt-5 pb-5 mb-5">
    <div class="container-page">
      <div class="found w-screen">
        <div class="container-main-home">
          <div class="display">
            <img class="imagen-page md:w-1/2" src="https://claveunica.denacon.cl/img/imagen.ddeddb96.svg" alt="logo">
            <div class="box-parrafo">
              <div class="card md:w-full">
                <div class="card-body">
                  <p class="title-form">Cierre de sesión</p><br>
                  <p class="subtitle-form">Procesando cierre de sesión.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix w-full"></div>
  </div>
  <div class="clearfix"></div>
  <fotter-component></fotter-component>


</template>

<script>
import FotterComponent from "@/components/FotterComponent";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "LogoutView",
  components: {
    HeaderComponent,
    FotterComponent
  },

  mounted(){
    this.logout()
  },
  methods: {
    logout() {
      window.location.href = process.env.VUE_APP_LOGOUT_REDIRECT
    }
  }
}
</script>

<style scoped>

</style>