<template>
  <div>

  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "RedirectView",
  data: function() {
    return {
      form_id: null,
      info: null
    }
  },
  methods: {
    getUserData() {
      this.axios
          .get(process.env.VUE_APP_API_URL + '/user?code=' + this.info.code
              + '&state='+this.info.state
              + '&form_id='+ this.form_id
          )
          .then(response => ( window.location.href = response.data.redirect_url));
    },
  },
  mounted() {
    let state = localStorage.getItem('state');
    this.info = this.$route.query;
    this.form_id = localStorage.getItem('form_id');

    if (state !== this.info.state) {
      alert('Existio un error en la solicitud de inicio de sesión debera iniciar nuevamente el proceso');
      router.push({ name: 'home', query: { form_id: this.form_id } });
    }

    this.getUserData()
  }
}
</script>

<style scoped>

</style>