<template>
  <header-component></header-component>
    <div class="container-full pt-5 pb-5 mb-5">
      <div class="container-page">
        <div class="w-full p-4 w-screen found">
          <div class="container-main-home ">
            <div class="display">
              <img class="imagen-page md:w-1/2" src="./../assets/img/imagen.svg" alt="logo">
              <div class="box-parrafo">
                <div class="card md:w-full">
                  <div class="card-body">
                    <p class="title-form">Bienvenido/a</p><br>
                    <p class="subtitle-form">Por favor para poder continuar identifíquese con clave única haciendo click en el siguiente botón.</p>
                    <div class="container-centrar">
                      <div class="my-5">
                        <a :href="cuUrl"
                           class="btn-cu btn-m btn-color-estandar"
                           title="Este es el botón Iniciar sesión de ClaveÚnica">
                          <span class="cl-claveunica"></span>
                          <span class="texto"> Iniciar sesión</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix w-full"></div>
    </div>
    <div class="clearfix"></div>
  <fotter-component></fotter-component>


</template>

<script>
// @ is an alias to /src
// Parámetro "form_id" es esperado para enviar parámetro en URL a Clave Unica
import FotterComponent from "@/components/FotterComponent";
import HeaderComponent from "@/components/HeaderComponent";
export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    FotterComponent
  },
  data () {
      return {
        cuUrl : process.env.VUE_APP_CU_URL + '&redirect_uri=' + process.env.VUE_APP_REDIRECT_URL,
      }
  },
  mounted() {
    let state = this.$uuid.v4();
    localStorage.setItem('state', state);

    if(this.$route.query.form_id !== null && this.$route.query.form_id !== undefined){
      localStorage.setItem('form_id', this.$route.query.form_id);
    }

    this.cuUrl = this.cuUrl + state;
  }
}
</script>

<style>
  @import '@/assets/styles/cu.min.css';
  @import '@/assets/styles/styles.css';
</style>